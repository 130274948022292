import React from "react";
import { Link } from "react-router-dom";

import Telefone from "../assets/image/contact-us/call-icon.svg";
import Email from "../assets/image/contact-us/email-icon.svg";
import Address from "../assets/image/contact-us/address-icon.svg";
import Facebook from "../assets/image/contact-us/fb-icon.svg";
import Instagram from "../assets/image/contact-us/insta-icon.svg";
import OpenHour from "../assets/image/contact-us/open-hour-icon.svg";

import RadoMap from "../assets/image/contact-us/map.png";

const ContactInfo = (props) => {
  return (
    <div className="row  mt-3 mb-3 d-flex justify-content-center">
      <div className="col-xl-7 col-md-7 col-12 order-md-1 order-2 pr-md-0">
        <div className="col-xl-12 d-none d-md-block pr-0 contact-us-bar"></div>
        <div className="col-xl-12 pr-md-0">
          <img src={RadoMap} alt="Rado cars" style={{ width: "100%" }} />
          {/* <Map location={location} zoomLevel={17} /> */}
        </div>
        <div className="col-xl-12 pt-2">
          <div className="d-flex justify-content-between">
            {props.showButton && <Link to={`/${props.lang}/Contact-us`}>
              <button className="btn btn-primary"> Get in touch</button>
            </Link>}
            <div className="d-flex align-items-center">
              <a
                className="mr-3"
                href="https://www.facebook.com/pages/category/Cars/Rado-cars-bvba-970133839817748/"
              >
                <img src={Facebook} alt="" />
              </a>
              <a
                className="mr-3"
                href="https://www.instagram.com/radocarsbvba/"
              >
                <img src={Instagram} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-5 col-12 order-md-2 order-1 pl-0 contact-info d-flex align-items-center ">
        <div className="flex-column">
          <div className="row contact-info-item ">
            <div className="col-xl-3 col-md-3 col-3">
              <img src={Address} alt="" />
            </div>
            <div className="col-9 pl-1">
              <h4 className="contact-info-item-title">{props.t9n.ADDRESS}</h4>
              <p>
                <span>
                  <strong>Oudenaardsesteenweg 279,</strong>
                </span>
                <br />
                <span>9420 Erpe Mere</span>
                <br />
                <span>Belgie</span>
              </p>
            </div>
          </div>
          <div className="row contact-info-item">
            <div className="col-xl-3 col-md-3 col-3">
              <img src={OpenHour} alt="" />
            </div>
            <div className="col-xl-9 col-md-9 col-9 pl-1">
              <h4 className="contact-info-item-title">{props.t9n.OPENINGHOURS}</h4>
              <p>
                {props.t9n.MandayToFridayShort}: 10:00 - 17:00 u
                <br />
                {props.t9n.SATURDAYShort}: 10:00 - 14:00 u
                <br />
                {props.t9n.SUNDAYShort}:  {props.t9n.CLOSED}
              </p>
            </div>
          </div>
          <div className="row contact-info-item">
            <div className="col-xl-3 col-md-3 col-3">
              <img src={Telefone} alt="" />
            </div>
            <div className="col-xl-9 col-md-9 col-9 pl-1">
              <h4 className="contact-info-item-title">{props.t9n.CALL_US}</h4>
              <p>
                <a href="tel:+3253617039">+32 (0)5 361 70 39</a>
              </p>
            </div>
          </div>
          <div className="row contact-info-item">
            <div className="col-xl-3 col-md-3 col-3">
              <img src={Email} alt="" />
            </div>
            <div className="col-xl-9 col-md-9 col-9 pl-1">
              <h4 className="contact-info-item-title">Email</h4>
              <p>
                <a href="mailto:info@radocars.be">info@radocars.be</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContactInfo;
